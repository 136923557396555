import axios from "axios";

export let headers = {
	"Tenant-Access-Token": "OTgzNWY5N2M1MjAyNjE3OTBkMjQ5ZDdkMzRjNTZlMzg=",
	"Tenant-Access-Key": "e9qnbwA4YHZdZw",
};

export const api = axios.create({
	baseURL: "https://tiltlabs-dev.confirmu.com",
	headers: {
		"Tenant-Access-Token": "OTgzNWY5N2M1MjAyNjE3OTBkMjQ5ZDdkMzRjNTZlMzg=",
		"Tenant-Access-Key": "e9qnbwA4YHZdZw",
	},
});

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";
